import colors from './colors';
import easings from './easings';
import fonts from './fonts';
import global from './global';
import grid from './grid';
import layout from './layout';
import responsive from './responsive';

const styles = {
    colors,
    easings,
    fonts,
    global,
    grid,
    layout,
    responsive,
};

export default styles;
export { colors, global, responsive, grid, layout, fonts, easings };
