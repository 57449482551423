import colors from './colors';
import responsive from './responsive';

const _fontStep = (paragraphFactor, headingFactor) => ({
    paragraphFactor,
    headingFactor,
});

const fontConfig = {
    paragraph: {
        family: '"Space Mono", monospace',
        lineHeight: 1.4,
        color: colors.black__off,
    },
    heading: {
        family: '"Space Mono", monospace',
        lineHeight: 1.5,
        color: colors.coral,
        beginSize: 24,
    },
    steps: {
        0: _fontStep(0.8, 0.8),
        10: _fontStep(0.8, 0.9),
        20: _fontStep(0.9, 1),
        30: _fontStep(1, 1),
        40: _fontStep(1, 1),
    },
};

const size = baseSize => {
    // Get current factor
    const factor =
        baseSize < fontConfig.heading.beginSize
            ? 'paragraphFactor'
            : 'headingFactor';

    // Get font sizess for each breakpoint
    const fontSize = responsive.breakpoints.reduce((acc, breakpoint) => {
        // Does config exist for current breakpoint (it really should)?
        if (fontConfig.steps[breakpoint.label]) {
            // Add to accumulator
            acc = [
                ...acc,
                ...(fontConfig.steps[breakpoint.label][factor] * baseSize +
                    'px'),
            ];
        }

        // Return
        return acc;
    }, []);

    // Return
    return responsive.mediaQueries({
        fontSize,
    });
};

export default {
    size,
    config: fontConfig,
};
