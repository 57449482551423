import { css } from '@emotion/core';
import fonts from './fonts';
import colors from './colors';

export default css`
    * {
        box-sizing: border-box;
        font-family: ${fonts.config.paragraph.family};
    }

    html {
        -webkit-font-smoothing: antialiased;
        height: 100%;
        overflow-x: hidden;
    }

    body {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${fonts.config.heading.family};
        line-height: ${fonts.config.heading.lineHeight};
        color: ${fonts.config.heading.color};
        ${fonts.size(fonts.config.heading.beginSize)}
        &:first-of-type {
            margin-top: 0;
        }
    }

    h1 {
        ${fonts.size(40)}
    }

    h2 {
        ${fonts.size(24)}
    }

    h3 {
        ${fonts.size(20)}
    }

    h4 {
        ${fonts.size(20)}
        font-weight: 300;
        color: ${fonts.config.paragraph.color};
    }

    h5 {
        ${fonts.size(18)}
        color: ${fonts.config.paragraph.color};
    }

    p,
    ul,
    ol {
        ${fonts.size(16)}
        font-family: ${fonts.config.paragraph.family};
        line-height: ${fonts.config.paragraph.lineHeight};
        color: currentColor;
        margin: 1.3em 0;
    }

    a {
        color: ${colors.coral};
    }
`;
