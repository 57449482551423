import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { PureComponent } from 'react';
import { Spring } from 'react-spring';
import { ReactComponent as SVGLogoFirst } from 'src/images/logo-first.svg';
import { ReactComponent as SVGLogoLast } from 'src/images/logo-last.svg';
import { colors } from 'src/styles';

class Logo extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            newWidth: 17,
            logoWidths: {
                min: 16,
                first: 73.31,
                last: 105.42,
            },
        };

        this.resizeEvent = this.resizeEvent.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
    }

    resizeEvent() {
        this.setState(
            () => ({
                windowWidth:
                    document.documentElement.clientWidth ||
                    document.body.clientWidth,
                documentHeight: document.documentElement.scrollHeight,
                windowHeight: window.innerHeight,
            }),
            () => this.scrollEvent()
        );
    }

    scrollEvent() {
        const {
            windowWidth,
            windowHeight,
            documentHeight,
            logoWidths,
        } = this.state;

        const scrollPosition = window.scrollY;

        if (scrollPosition > 0) {
            const scrollPercentage =
                scrollPosition / (documentHeight - windowHeight);

            const newWidth = Math.max(
                (windowWidth - logoWidths.first - logoWidths.last) *
                    scrollPercentage,
                logoWidths.min
            );

            this.setState(() => ({
                newWidth,
            }));
        } else {
            this.setState(() => ({
                newWidth: 16,
            }));
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeEvent);
        setTimeout(() => {
            window.addEventListener('scroll', this.scrollEvent);
            this.resizeEvent();
        }, 20);
    }

    render() {
        return (
            <LogoWrapper>
                <LogoFirst>
                    <SVGLogoFirst />
                </LogoFirst>
                {typeof window !== 'undefined' && (
                    <Spring
                        config={{ tension: 210, friction: 20 }}
                        from={{ width: 16 }}
                        to={{ width: this.state.newWidth }}>
                        {props => <LogoMiddle {...props} />}
                    </Spring>
                )}
                <LogoLast>
                    <SVGLogoLast />
                </LogoLast>
            </LogoWrapper>
        );
    }
}

export default Logo;

const LogoWrapper = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
`;

const LogoPart = css`
    height: 20px;
    svg {
        height: calc(103.22px * 0.193760899);
        width: calc(378.42px * 0.193760899);
    }
    path {
        fill: ${colors.blue};
    }
`;

const LogoFirst = styled.div`
    ${LogoPart}
`;

const LogoLast = styled.div`
    ${LogoPart}
    svg {
        width: calc(544.09px * 0.193760899);
    }
`;

const LogoMiddle = styled.div`
    will-change: width;
    background: ${colors.blue};
    width: ${props => props.width}px;
    height: 20px;
    margin: 0 -1px;
`;
