import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';

const SEO = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    datoCmsSeoMetaTags {
                        ...GatsbyDatoCmsSeoMetaTags
                    }
                }
            `}
            render={data => <HelmetDatoCms seo={data.datoCmsSeoMetaTags} />}
        />
    );
};

export default SEO;
