export default {
    black__off: '#515d6c',
    black: '#000',
    blue__dark: '#1f2834',
    blue: '#233063',
    coral: '#e04a42',
    gray__dark: '#b2b2b2',
    gray: '#e5e5e5',
    white__off: '#f7f7f7',
    white: '#fff',
};
