import { css } from 'emotion';

const fullWidthBackground = color => css`
    position: relative;
    background-color: ${color};
    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: 50%;
        background-color: ${color};
        transform: translateX(-50%);
        z-index: -1;
    }
`;

export default {
    fullWidthBackground,
};
