import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import 'normalize.css';
import React, { Component } from 'react';
import Grid from 'src/components/grid';
import Header from 'src/components/header';
import { global, grid } from 'src/styles';
require('typeface-space-mono');

class LayoutComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children } = this.props;
        return (
            <>
                <Global styles={global} />
                <Header />
                <Wrapper>
                    <Container>{children}</Container>
                </Wrapper>
                {process.env.NODE_ENV === 'development' && <Grid />}
            </>
        );
    }
}

const Wrapper = styled.div`
    width: 100vw;
`;

const Container = styled.div`
    margin: 0 auto;
    ${grid.contentWidth};
    ${grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })}
`;

export default LayoutComponent;
