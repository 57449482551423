import React, { Component } from 'react';
import Logo from './logo.component';

class Header extends Component {
    render() {
        return <Logo />;
    }
}

export default Header;
